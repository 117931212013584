// VENDORS JS

// Form Validation
// https://formvalidation.io/guide/getting-started/

@import './src/assets/vendors/es6-shim/es6-shim.min.js'
@import './src/assets/vendors/formvalidation/js/FormValidation.full.min.js'
@import './src/assets/vendors/formvalidation/js/plugins/AutoFocus.js'

// Fancybox - lightbox
// https://fancyapps.com/fancybox/getting-started/

@import './src/assets/vendors/fancybox/fancybox.umd.js'

// Swiper - mobile touch slider
// https://swiperjs.com/get-started

@import './src/assets/vendors/swiper/swiper-bundle.min.js'

// Lozad.js - lazy loading
// https://apoorv.pro/lozad.js/#usage

@import './src/assets/vendors/lozad/lozad.min.js'